// axios请求
import axios from "axios";
// 局部引入element-plus指定类型的弹窗组件
import { ElMessageBox } from "element-plus";
// 引入路由跳转方法
import { useRouter } from "vue-router";
// 将路由跳转方法设为常量方便调用
const router = useRouter()

// 创建通用axios配置
// create 是axios自带的方法,可以方便地创建一个自定义配置的Axios实例
let instance = axios.create({
    responseType:"json", //返回数据类型
    headers:{'Content-Type':'application/json'}, // 请求头 
})

// 请求拦截器:是在axios请求发出之前,给每个接口携带token去后端校验身份
instance.interceptors.request.use(
    // 回调参数一:
    config =>{
        // 从缓存中获取后台返回的token
        let token = localStorage.getItem('token') 
        if (token) {
            /*header里面放Authorization，就是为了验证用户身份。
            Authorization里面放的就是token，就相当于每次发送请求的时候，拦截器都会拦截一次你的请求，来验证请求的token与储存的token是否是一致的。
            如果一致会把当前请求放行，
            如果不一致那么服务器会截断你的请求并把错误码返给前端，让前端验证身份重新登陆*/
            config.headers.Authorization = token
        }
        return config
    },
    // 回调参数二:
    err =>{
        return Promise.reject(err)
    }
)
// 响应拦截器:是在axios请求发出之后,
instance.interceptors.response.use(
    // 回调参数一:
    response =>{
        return  response
    },

    // 回调参数二:
    error =>{
        if (error.response) {
            let errs = error.response.status
            let msg = error.response.data.msg.msg
            let errdata = errs == 401?msg:"服务器发生错误"
            switch (errs) {
                case 401:
                    ElMessageBox.alert(errdata,"提示",{
                        confirmButtonText:"确认",
                        type:"error"
                    })
                    .then(res=>{
                        // 跳转到登录页面 
                        localStorage.clear() // 清除缓存
                        router.push({ 'name': 'login' })
                    })
                    .catch(err=>{
                        console.log(err)
                    })
                    break;
            
                default:
                    break;
            }
        }
        // 返回接口的错误信息
        return Promise.reject(error.response)
    }
)

export default  instance