import { createRouter, createWebHistory } from 'vue-router';
 
// 定义一些路由
// 每个路由都需要映射到一个组件
const routes = [
    { 
    path: '/',          
    name:'login',
    component:()=>import(/*webpackChunkName:'Login'*/ '../page/login/Login.vue') 
    },
    { 
      path: '/index',          
      name:'index',
      component:()=>import(/*webpackChunkName:'Index'*/ '../page/index/Index.vue'),
      // 二级路由定位到哪个页面，字段名固定
      redirect:'/home',
      // 二级路由，字段名固定
      children:[
        { 
          path: '/home',          
          name:'home',
          component:()=>import(/*webpackChunkName:'Home'*/ '../page/home/Home.vue') 
        },
        { 
          path: '/set',          
          name:'set',
          component:()=>import(/*webpackChunkName:'Set'*/ '../page/set/Set.vue') 
        },
        { 
          path: '/region',          
          name:'region',
          component:()=>import(/*webpackChunkName:'Region'*/ '../page/region/Region.vue') 
        },
        { 
          path: '/camera',          
          name:'camera',
          component:()=>import(/*webpackChunkName:'Camera'*/ '../page/camera/Camera.vue') 
        },
        { 
          path: '/parkingSpace',          
          name:'parkingSpace',
          component:()=>import(/*webpackChunkName:'ParkingSpace'*/ '../page/parkingSpace/ParkingSpace.vue') 
        },
        { 
          path: '/screen',          
          name:'screen',
          component:()=>import(/*webpackChunkName:'Screen'*/ '../page/screen/Screen.vue') 
        },
        { 
          path: '/search',          
          name:'search',
          component:()=>import(/*webpackChunkName:'Search'*/ '../page/search/Search.vue') 
        }
      ] 
    },
    { 
      path: '/searchCar',          
      name:'searchCar',
      component:()=>import(/*webpackChunkName:'SearchCar'*/ '../page/searchCar/SearchCar.vue') 
    }
];
 
// 创建router实例
const router = createRouter({  
  history: createWebHistory(),
  routes
});
 
// 导出router实例
export default router;
