import { createApp } from 'vue'
import App from './App.vue'
// 引入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// ElementPlus 提供了全局配置国际化的设置。
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// 引入路由 
import router from './router/index.js'

// 全局引用css
import '../style/handtap.css'

const app = createApp(App)
// 挂载路由 
app.use(router)
// 挂载element-plus
app.use(ElementPlus, {locale: zhCn})

// 引入api文件
import urls from "../api/api.js"
// 挂载请求地址,$urls是命名,前面是固定格式
app.config.globalProperties.$urls = urls
// 引入request文件
import request from '../api/request.js'
app.config.globalProperties.$request = request

// 弹窗提示
import tips from "../config/Tips.js";
app.config.globalProperties.$tips = tips

app.mount('#app')
