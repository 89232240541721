<template>
  <router-view/>
</template>

<script>
import {  } from "vue";
export default {
  // 将组件对象注册到局部组件池中
  "components":{

  },
  setup(){

  }
}
</script>

<style scoped>

</style>
