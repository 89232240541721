// 公用的弹窗
import { ElMessage } from 'element-plus'

// 创建一个弹窗类
const tips = class{
    // 创建构造方法接收参数，弹窗文本和类型，默认类型success
    constructor(message,type="success"){
        this.message = message
        this.type = type
    }

    // ElMessage提示
    mess_age(){
        ElMessage({
            message: this.message,
            type: this.type,
            duration:1500
        })
    }
}

export default tips