// 引入封装了请求前拦截和请求后拦截的axios请求
import instance from "./header.js"

// 创建类
const request = class{
    // 构造函数
    constructor(url,arg){
        this.url = url
        this.arg = arg
    }

    // poss请求
    modepost(){
        // 返回一个Promise,resolve是通过,reject是不通过
        return new Promise((resolve, reject)=>{
            instance.post(this.url,this.arg)
            .then(res=>{
                resolve(res)
            })
            .catch(err=>{
                reject(err)
            })
        })
    }

    // get请求
    modeget(){
        return new Promise((resolve, reject) => {
            instance.get(this.url,this.arg)
            .then(res=>{
                resolve(res)
            })
            .catch(err=>{
                reject(err)
            })
        })
    }
}

export default request